import {Box, Button, Container, Stack, TextField, Typography} from "@mui/material";
import {motion, useAnimation, Variants} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import React from "react";


function Hero2() {
    const variantText: Variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 , transition: { duration: 2.8 } },
    };

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    return(
        <>
            <Container maxWidth={false} className="hero-container ">
                <Container className="hero2" sx={{borderLeft: '#54abc9', borderLeftStyle: 'solid', borderLeftWidth: '10px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', backgroundColor: "#EEF7FA" }}>
                <Stack direction={{xs: 'column', md: 'row' }} spacing={4}>
                    <Box component="img" src="/hero.webp" width="400" height="400" alt="Netbrain" className="logo" />
                    <div>
                    <Typography component={motion.h2}
                                variant={"h1"}
                                variants={variantText}
                                ref={ref}
                                initial="hidden"
                                animate={controls}
                                sx={{color: '#54abc9'}}

                    >Egy weboldal nem hoz nagy változást vállalkozásod életében, viszont egy jó
                        weboldal igen.
                    </Typography>
                        <Typography component="p" variant={"h5"}>
                20 éves fejlesztői tapasztalattal széles körű minőségi szolgáltatást nyújtunk. Meghallgatjuk
                problémáidat és közösen megtervezzük számodra a legmegfelelőbb informatikai megoldásokat.
                Az első konzultáció ingyenes.
                        </Typography>
                    </div>
                </Stack>
                </Container>
             </Container>
        </>
    )
}

export default Hero2