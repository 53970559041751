import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import Header from "./Header";
import React from "react";
import {Container} from "@mui/material";
import Footer from "./Footer";
import NewsLetterPopup from "./NewsLetterPopup";

function BlogGoogleMisconception() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Google tévhitek</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">Google által cáfolt SEO tévhitek </h1>
                    </header>
                    <div className="post-headline">
                        <h2 className="post-headline-title">
                            Faggatjuk a Google-t .... jahh nem, magától is elárulja, hogy működik.
                            Weboldalaink keresőmotor optimalizálásánál (SEO) különös figyelmet kell fordítanunk a
                            keresésben piacvezető Google tanácsaira. Az alábbiakban olvashatod azokat a SEO tanácsokat,
                            amelyek találataik szerint széles körben terjednek, de szerintük nem szabad rájuk fókuszálni.
                        </h2>
                    </div>
                    <div className="post-image center">
                        <img src="/blog_google_tevhitek.webp" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <p>
                            Az évek, évtizedek során folyamatosan fejlődött az internet, a keresőmotorok, és a
                            keresőmotorokat kijátszók technikája. A Google igyekszik reagálni ezekre az ügyeskedésekre,
                            és javítja, finomítja a keresőmotorját, így fordulhat elő, hogy a korábban bevált praktika
                            mára már nem működik hatékonyan.
                        </p>
                        <p>
                            Ez nem azt jelenti, hogy ezeket felejtsd el, de ne csak ezekre fókuszálj, mivel az
                            algoritmus jóval összetettebb annál, minthogy egyetlen egy elem jó beállításával rögtön a
                            keresőlisták élére kerülnél.
                        </p>
                        <p>
                            Akkor nézzük az aktuális listát:
                        </p>
                        <h4>
                            Meta kulcsszavak / Meta keywords
                        </h4>
                        <p>
                            Még 2009 előtt játszottak nagy szerepet a keresőmotor működésében a metacímkék, de a túl
                            sok visszaélés hatására jelenleg nem használja a keresőmotor a rangsoroláshoz az ide beírt
                            kulcsszavakat. Ugyanakkor továbbra is töltsd ki a többi meta részt, mint például a meta
                            leírást (meta description), mivel a Google a találatok megjelenítésekor ezt kiírja.
                        </p>
                        <h4>
                            Túlzott kulcsszó használat
                        </h4>
                        <p>
                            Biztos találkoztál már olyan oldallal, ahol egymás után több száz kulcsszó van felsorolva,
                            mindenféle szövegkörnyezet nélkül. Nah, ez teljesen felesleges. Sőt ugyanazon szavakat sem
                            érdemes ismételgetni - különböző variációkban sem -, mivel ezt a kereső spamnek (kéretlen
                            információknak) tekinti.
                        </p>
                        <p>
                            A kulcsszavakat az algoritmus csak releváns szövegkörnyezetben fogadja el (vizsgálja hozzá
                            a tágabb kontextust, például az oldalon lévő médiafájlokat is).
                        </p>
                        <h4>
                            Kulcsszavak a domain névben vagy az URL elérési útjában
                        </h4>
                        <p>
                            A rangsoroláskor nem számít, hogy milyen kulcsszavakat írtál be a domain nevbe, vagy az URL
                            elérési útjába. De, a kereső találati megjelenítésénél szerepelni fog, így mindenképpen
                            töltsd ki relevánsra, mert egy passzoló kulcsszó segítheti, hogy a keresőt használó éppen
                            kire fog rákattintani a megjelent listából.
                        </p>
                        <p>
                            Gyakori kérdés még, hogy mennyire számít, hogy mire végződik egy domain név (.com, .org,
                            .hu stb.) A keresőmotor nem foglalkozik vele, ennél összetettebb módszerrel határozza meg,
                            hogy kinek szól a weboldal (pl. címek, telefonszámok, nyelv, pénzegység, linkek stb.).
                            Ugyanakkor példaként hozza a Google, hogy a célközönséged országa szerinti domain neved
                            legyen, pl. magyar terméket szeretnél osztrák közönségnek, akkor a .at végződés jobb lehet.
                        </p>
                        <h4>
                            A tartalom hossza
                        </h4>
                        <p>
                            Erre rangsorolás szempontjából nincs konkrét válasz. A kereső azt várja el, hogy természetes
                            szövegkörnyezetben (a feljebb már említett spamnek minősülő kulcsszóhalmozásokat elkerülve)
                            találja meg a releváns kifejezéseket. Persze minél hosszabb a szöveg, annál több szó, így
                            annál több kulcsszó férhet bele.
                        </p>
                        <h4>
                            Aldomainek vagy alkönyvtárak használata
                        </h4>
                        <p>
                            Keresőmotor szempontjából mindegy melyiket használod. Itt a te üzleti szempontjaidra
                            támaszkodj, úgy oszd fel, ahogy vállalkozásod számára logikusabb.
                        </p>
                        <h4>
                            PageRank
                        </h4>
                        <p>
                            A PageRank az egyik legelső módszer volt, amit a Google használt a weboldalak
                            rangsorolására, mára azonban az algoritmus sokkal összetettebb így a szerepe is kisebb. A
                            PageRank lényege, hogy az oldalra mutató linkek alapján helyezési sorrendet állít fel. A
                            linkeknek relevánsnak (pl. a szövegkörnyezettel ellenőrzi), és értékesnek (nem mindegy
                            milyen oldalról származik).
                        </p>
                        <h4>
                            Büntetik az ismétlődő tartalmat
                        </h4>
                        <p>
                            Saját tartalmad lehet több URL címen is, nem fogsz érte büntetést kapni, de a keresőben
                            valószínűleg nem fogsz jó helyen megjelenni, mivel a több találat között megoszlik az
                            eredmény. (Ez a saját tartalomra vonatkozik, mások tartalmának másolása már szabályzatba
                            ütközik. Nem szabad plusz hozzáadott érték, illetve forrásmegjelölés nélkül tartalmakat
                            átvenni másoktól.)
                        </p>
                        <h4>
                            A címsorok száma és sorrendje
                        </h4>
                        <p>
                            Az, hogy weboldalad hány címsort tartalmaz, és azok milyen logikai sorrendben találhatóak
                            meg a keresőmotor szempontjából nem lényeges, nincs ideális számú címsor és sorrend. A jó
                            felépítés a honlapra látogatók számára fontos.
                        </p>
                        <h4>
                            Az E-E-A-T rangsoroló tényező
                        </h4>
                        <p>
                            Az E-E-A-T (experience, expertise, authoritativeness, and trustworthiness) magyarul
                            tapasztalat, szakértelem, tekintély és megbízhatóság nem rangsorolási tényező.
                        </p>
                        <p>
                            A Google algoritmusa nagyon összetett, ennek egyik része, hogy releváns tartalmak közül a
                            leghasznosabb tartalmakat részesítsék előnyben. A fenti négy tényező közül a bizalom a
                            legfontosabb. E-E-A-T nagyobb hangsúllyal számít az olyan tématerületeknél, ahol az emberek
                            egészsége, pénzügyi biztonsága és jólétével foglalkozik.
                        </p>
                        <p>
                            Röviden azt lehet tanácsolni, hogy hasznos, megbízható, emberközpontú tartalmat készíts.
                        </p>
                        <a href="https://developers.google.com/search/docs/fundamentals/seo-starter-guide#focusing "
                            target="_blank">
                            Forrás
                        </a>
                        <p>&nbsp;</p>
                    </div>
                </article>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogGoogleMisconception;