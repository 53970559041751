import {Box, Button, Container, Stack, TextField, Typography} from "@mui/material";
import {motion, useAnimation, Variants} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import React from "react";


function Hero() {
    const divVariants: Variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 2.0 } },
    };

    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.1
    });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);


    return(
        <>
            <Container className="hero-container">
                <Stack direction={{xs: 'column', md: 'row' }} spacing={4}>
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={divVariants}
                    >
                        <Stack direction="column" spacing={3} className="">
                            <Typography component="h1" variant="h2" className="hero1" sx={{fontWeight: 'bold'}}>
                                Növeld profitod egyedi fejlesztésű<br/>
                                <span className="">weboldallal.</span>
                            </Typography>
                            <Typography component="h4" variant="h5" className="">Szem előtt tartjuk a vállalkozásod egyedi
                                igényeit,
                                <br className=""/> hatékonyan támogatjuk a gyors ütemű fejlődésed!
                            </Typography>


                        </Stack>
                    </motion.div>
                    <Box component="img" src="/logo.webp" alt="Netbrain" width="400" height="400" className="logo hero-logo" />
                </Stack>
            </Container>
        </>
    )
}

export default Hero