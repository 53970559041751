import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Container} from "@mui/material";
import Header from "./Header";
import NewsLetterPopup from "./NewsLetterPopup";

function BlogFree() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Ingyen weblap?</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">Teljesen ingyenes weboldal készítés lehetséges? Ingyen honlap készítés programozói tudás
                            nélkül? Mire használható az ingyenes weblap?</h1>
                    </header>
                    <div className="post-headline">
                        <h2 className="post-headline-title">
                            Cikkünkben megmutatjuk, hogy az ingyenesen elkészített honlapoknak valójában milyen
                            költségei vannak. Szó lesz a legnépszerűbb ingyenes tartalom kezelő rendszerről, a
                            WordPressről, amely akár programozói tudás nélkül is használható. Megnézzük milyen előnyei
                            és hátrányai vannak ezeknek az ingyenes sablon webodalaknak, illetve az egyedi készítésű
                            honlapoknak. Példákat hozunk arra, hogy kiknek ajánljuk, hogy saját maguk összeállítsanak egy
                            honlapot, és milyen esetekben javasoljuk, hogy inkább szakemberhez forduljatok.
                        </h2>
                    </div>
                    <div className="post-image center">
                        <img src="/blog_free.jpg" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <h4>
                            Ingyenes honlap rejtett és kevésbé rejtett költségei
                        </h4>
                        <p>
                            Ma már rengeteg ingyenes weboldalkészítési lehetőséget reklámoznak az interneten. Azonban
                            fontos tisztában lenni azzal, hogy 100 százalékosan ingyenes honlapkészítés nem létezik.
                            Minden honlap költséggel jár, még akkor is, ha elsőre ez nem tűnik nyilvánvalónak.
                        </p>
                        <p>
                            Leggyakoribb költségek:
                            <ul>
                                <li>
                                    Egy honlap alapvető technikai szükséglete a domain név és a tárhely. Saját domain névért és
                                    értelmezhető méretű tárhelyért mindenhol fizetni szükséges!
                                </li>
                                <li>
                                    Amiért általában nem kell fizetni az maga az egyszerű, nem túl bonyolult sablon. A szebb,
                                    modernebb, több tudással rendelkező sablonok, bővítmények fizetősek.
                                </li>
                                <li>
                                    Ha saját magad állsz neki a honlap készítésnek, akkor ne felejtsd el kiszámolni, hogy hány
                                    munkaórát töltöttél el vele és ehelyett mennyi pénzt tudtál volna keresni. Egyszerűség
                                    kedvéért számolhatsz a KSH 2024. áprilisi bruttó átlagkereset adatával is, ami 645 ezer Ft
                                    volt. Ha mondjuk 2 hét alatt (80 óra) átrágod az útmutatókat, megtervezed a honlapod,
                                    kiválasztod a szükséges elemeket, képeket válogatsz, képeket szerkesztesz és összehúzkodod
                                    őket, feltöltöd tartalommal, akkor 80 óra X 4 ezer Ft/óra = 320 ezer Ft bérköltséggel simán
                                    számolhatsz.
                                </li>
                                <li>
                                    Amiről mindenki megfeledkezik: a fenntartási, és a rendszeres karbantartási és frissítési
                                    költségek. Ezek között vannak rendszeres költségek, mint a domain név vagy tárhely, amiket
                                    évente/havonta szükséges fizetni. Illetve időszakonként felmerülő költségek, mint a technikai
                                    frissítések. Ezekről lesz külön cikk…
                                </li>
                                <li>
                                    Ha a karbantartást és frissítést saját magad végzed, ott megint szükséges lesz kiszámolni,
                                    hogy hány munkaórát töltöttél el vele és ez mennyibe került neked. Ha a mindennapokban
                                    nem informatikával foglalkozol, akkor észre fogod venni, hogy a korábbi tudásod milyen
                                    gyorsan kopik és a technológia akár fél év alatt mennyit változik. Így újra elő kell majd venni
                                    az útmutatókat és jópár órát fogsz ezzel elszöszmötölni.
                                </li>
                            </ul>
                        </p>
                        <h4>
                            Alapvető tudnivalók a WordPressről
                        </h4>
                        <p>
                            Honlapot készíteni a tartalomkezelő rendszerek (CMS - Content Management System)
                            segítségével tudsz. Ezek a rendszerek általában gyorsan telepíthetőek, pár perc után létrejöhet
                            egy alap weblap, amit aztán tovább lehet alakítgatni. Nem kell programozónak lenned ahhoz,
                            hogy egy oldalt össze lehessen rakni, kiegészítőket tudsz hozzájuk tenni, ha bővíteni szeretnéd az
                            oldalad tudását.
                        </p>
                        <p>
                            Több kisebb-nagyobb ilyen rendszer is létezik, ezek közül a legismertebb és a legnépszerűbb
                            tartalomkezelő rendszer a WordPress. Több ezer sablon és kiegészítő közül válogathatsz (az
                            alapok ingyenesek, az extrák már fizetősek). Ingyenes oktatási anyagokat, illetve fizetős oktatási
                            anyagot és támogatást is tudsz szerezni az oldalad elkészítéséhez. Így kis időbefektetéssel pöpec
                            honlapokat tudsz magadnak összeállítani.
                        </p>
                        <p>
                            A WordPress egy nyílt forráskódú PHP-ban írt keretrendszer. PHP mellett még HTML-t, CSS-t
                            és JavaScriptet is használ a WordPress. Így például amennyiben megtanulod a PHP nyelv
                            alapjait, hozzá tudsz nyúlni az egyes bővítményekhez, tudsz egyedi feldolgozást vagy
                            lekérdezést készíteni magadnak.
                        </p>
                        <p>
                            A fizetős sablonok és bővítmények használatával viszont akár jelentős összegbe is kerülhet az
                            általad megálmodott oldal.
                        </p>
                        <h4>
                            Ingyenes sablonok előnyei és hátrányai
                        </h4>
                        <p>
                            Előnyei:
                            <ul>
                                <li>
                                    Egyszerűen kezelhető felület
                                </li>
                                <li>
                                    Rengeteg ingyenes sablon és sok ingyenes bővítmény található
                                </li>
                                <li>
                                    Számos ingyenes (illetve megfizethető árú) tananyag
                                </li>
                                <li>
                                    Alacsonyabb költségvetéssel ki lehet hozni (a tárhelyet és a domain nevet meg kell vásárolni)
                                </li>
                                <li>
                                    Akár pár hét alatt is elkészülhet
                                </li>
                                <li>
                                    Te saját magad is összerakhatod, ha rendelkezel elég szabadidővel, és szeretnél jobban
                                    belemerülni a honlapkészítésbe.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Hátrányai:
                            <ul>
                                <li>
                                    A sablonokon korlátozott az ingyenes módosítási lehetőség (még színek esetében is), így
                                    nehéz egyedinek lenni.
                                </li>
                                <li>
                                    A bővítmények nem pont azt, és nem pont úgy csinálják, mint amire neked szükséged van,
                                    ezért többet használsz a kelleténél és ez az oldal jelentős lassulását okozhatja.
                                </li>
                                <li>
                                    Ha extra sablonokat és bővítményeket kezdesz el használni, azok megnövelik a költségeidet.
                                </li>
                                <li>
                                    Nyílt forráskód miatt nagyobb a sebezhetőség és a hackerek is szeretik támadni, mivel
                                    nagyobb a találati esélyük.
                                </li>
                                <li>
                                    Ha saját magad készíted a honlapod, az lehet jó lesz és megfelelően fog működni, de sosem
                                    lesz olyan, mintha egy profi készítette volna, nem fog minőségével kitűnni a versenytársak
                                    közül.
                                </li>
                                <li>
                                    A honlap karbantartásával és rendszeres frissítésével is neked kell foglalkozni.
                                </li>
                            </ul>
                        </p>
                        <h4>
                            Az egyedi honlapok előnyei és hátrányai
                        </h4>
                        <p>
                            Előnyei:
                            <ul>
                                <li>
                                    Az oldalad valóban egyedi lesz, olyan amilyet szeretnél, és amire vállalkozásodnak szüksége
                                    van.
                                </li>
                                <li>
                                    Minden számodra felesleges komponenst elhagyhatsz, nem kell igazodnod másokhoz.
                                </li>
                                <li>
                                    Összetett, sok funkcióra is kiválóan alkalmas.
                                </li>
                                <li>
                                    Biztonságos, az egyedi kódoknak köszönhetően, jóval kevesebb támadás éri ezeket a
                                    felületeket, ezzel együtt kevesebb lesz a karbantartásra fordítandó idő is.
                                </li>
                                <li>
                                    Gyorsabb, mert csak azokat a bővítményeket tartalmazza, amire szükséged van.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Hátrányok:
                            <ul>
                                <li>
                                    Költségesebb, mint sablonokat használni.
                                </li>
                                <li>
                                    Időigényes, több hét, hónap mire elkészül.
                                </li>
                                <li>
                                    Ha változtatni, módosítani szeretnél a weboldaladon akkor szükséged lesz a programozóra,
                                    vagy programozói tudásra.
                                </li>
                            </ul>
                        </p>
                        <h4>
                            Mikor milyen honlapot válasszak?
                        </h4>
                        <p>
                            Amennyiben kezdő vállalkozó vagy, és egy egyszerű landing oldal megfelel a céljaidnak, ahol az
                            alapinformációk megtalálhatóak rólad, illetve ha blogot írsz, akkor a sablonokat neked találták
                            ki. A WordPress kifejezetten blogok készítésével indult, de az évek során folyamatosan bővült,
                            így már más típusú honlapok készítésére is reklámozzák.
                        </p>
                        <p>
                            Ha van elég szabadidőd és szereted a kihívásokat, érzed magadban az erőt, a kreativítást és a
                            kíváncsiságot, saját magad is össze tudod rakni sablonok segítségével az oldalad. De mindig
                            mérlegeld, hogy a bele fektetett időd (idő egyenlő pénz, mindig vedd számításba, hogy a
                            weblapkészítéssel eltöltött idő alatt mennyi pénzt tudtál volna keresni) és a honlappal elérni
                            kívánt cél összhangban legyen.
                        </p>
                        <p>
                            Ha saját magad állsz neki a honlap készítésnek fontos, hogy előzetesen minél jobban határozd
                            meg mit szeretnél, különben a több ezer sablon és bővítmény között napokig fogsz bolyongani.
                            Egy tervvel a kezedben sem lesz egyszerű megtalálni az igazit, de határozottan könnyebb.
                        </p>
                        <p>
                            Sablonok segítségével összetett honlapok, webshopok is készíthetőek, azonban ilyenkor
                            szükséges megvizsgálni, hogy technikailag (betöltési idő, reszponzivítás stb.) tudja-e a honlap a
                            minimálisan elvártat. Továbbá, ami nagyon fontos és nem elhanyagolható: kellően biztonságos
                            tud-e maradni hosszú távon az oldalad, pláne ahol fizetés is történik.
                        </p>
                        <p>
                            Vedd figyelembe, hogy sablonokkal mindent, amire a vállalkozásodnak szüksége van, nem
                            biztos, hogy meg tudsz valósítani, ezek sablon termékek, nem feltétlenül tudja a te igényeidet
                            kielégíteni, hiszen lehet, hogy ez az igény tömegesen még nem merült fel (vagy technikailag
                            ilyen formában meg sem valósítható).
                        </p>
                        <p>
                            Gondolj bele, hogy a sablonokat viszonylag könnyű felismerni, így egy nagy vállalkozás
                            hitelességét az ügyfelei, partnerei bizalmát jelentősen csökkenti, hogy a honlapja csak egy olcsó
                            másolat.
                        </p>
                        <p>
                            Egyedi honlapokkal tudsz a legújabb trendeknek megfelelni és közben vállalkozásod
                            egyéniségének igényeit is kiszolgálni, miközben weblapod technikai és biztonsági követelményei
                            sem szorulnak háttérbe.
                        </p>
                        <p>
                            A fentiek alapján összességében elmondhatjuk, hogy a honlap készítés nem ingyenes, de olcsó
                            honlap létrehozása nem lehetetlen küldetés. A kérdés az, hogy mire szeretnéd használni az
                            oldaladat, és pénzt szeretnél vele keresni, vagy csak egy kötelező elemnek tekinted vállalkozásod
                            életében.
                        </p>
                        <p>
                            És ne felejtsd el, a honlapokat időről időre frissíteni, karbantartani szükséges.
                        </p>
                        <div style={{color: "#54abc9"}}>
                            <p>
                                Amennyiben úgy érzed, hogy segítségre van szükséged és a honlapod szakemberrel
                                készítenéd el, keress minket bizalommal. Több, mint 20 éves tapasztalattal rendelkezünk
                                informatikai fejlesztés területén.
                            </p>
                        </div>
                    </div>
                </article>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogFree