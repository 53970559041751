import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";

function NewsLetterPopup() {
    const [open, setOpen] = useState(false);
    /*const [hasDialogOpened, setHasDialogOpened] = useState(() => {
        // Ellenőrizzük, hogy a sessionStorage-ben létezik-e a kulcs
        return sessionStorage.getItem("hasDialogOpened") === "true";
    });*/
    const [isUserScrolling, setUserScrolling] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });

    useEffect(() => {
        const handleScroll = () => {
            setUserScrolling(true);
            const scrollPosition = window.scrollY + window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Ha a felhasználó az oldal aljára ér
            if (scrollPosition >= documentHeight && !(sessionStorage.getItem("hasDialogOpened") === "true")) {
                setOpen(true);
            }
        };

        const handleStopScrolling = () => {
            setUserScrolling(false); // Ha nem görget, állítsuk le a figyelést
        };

        // Görgetési esemény figyelése
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleStopScrolling);

        // Takarítás az esemény eltávolításával
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("scroll", handleStopScrolling);
        };
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Form adatainak JSON formátumba alakítása
        const jsonData = JSON.stringify(formData);

        // Adatok elküldése a szerverre
        fetch('https://oalvxevtnd4gpwrgek5poe5czq0hziah.lambda-url.eu-west-1.on.aws/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                setOpen(false)
                sessionStorage.setItem("hasDialogOpened", "true");
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const label = (
        <Box component="span" >Elfogadom az&nbsp;
            <a href="/adatvedelmi.pdf"
               target="_blank"
                //onClick={onLinkClick}
            >
                adatkezelési tájékoztatóban
            </a>
            &nbsp;foglaltakat!
        </Box>
    )



    const handleClose = () => {
        setOpen(false);
    };

    return(
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                        <Typography variant="h4" gutterBottom >Tetszett a cikkünk? Iratkozz fel hasonló tartalmakért</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Nem árasztjuk el a postaládádat spammel, csak hasznos információkat küldünk heti rendszerességgel.
                    </Typography>
                    <p>&nbsp;</p>
                    <form action="https://oalvxevtnd4gpwrgek5poe5czq0hziah.lambda-url.eu-west-1.on.aws/" onSubmit={handleSubmit}
                          method="POST">
                        <Stack direction="column" spacing={5} className="row">
                            <TextField id="name"
                                       name="name"
                                       type="text"
                                       value={formData.name}
                                       onChange={handleChange}
                                       label="Keresztnév"
                                       variant="filled"
                                       InputLabelProps={{ style: { color: "black" } }}
                                       sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black'}}
                                       required />
                            <TextField id="email"
                                       name="email"
                                       value={formData.email}
                                       onChange={handleChange}
                                       type="email"
                                       label="E-mail"
                                       variant="filled"
                                       InputLabelProps={{ style: { color: "black" } }}
                                       sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                       required />
                            <FormControlLabel required classes={{ asterisk: 'Mui-required' }} control={<Checkbox />} label={label} />
                            <div className="col-lg-12 mt-2">
                                <button type="submit" className="message-button cta pointer">
                                    Kérem a hirleveleket
                                </button>
                            </div>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default NewsLetterPopup;