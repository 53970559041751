import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ChangeEvent, CSSProperties, FormEvent, ReactNode, useState} from "react";
import {
    Box,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import LandingFooter from "./LandingFooter";
import {useNavigate} from "react-router-dom";

function LandingSeoAudit() {
    const navigate = useNavigate();
    const { scrollYProgress } = useScroll();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const label = (
        <Box component="span">Elfogadom az&nbsp;
            <a
               href="/adatvedelmi.pdf"
               target="_blank"
                //onClick={onLinkClick}
            >
                adatkezelési tájékoztatóban
            </a>
            &nbsp;foglaltakat!
        </Box>
    )

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        // Form adatainak JSON formátumba alakítása
        const jsonData = JSON.stringify(formData);

        // Adatok elküldése a szerverre
        fetch('https://cw2csgr45jf47qstufttel4rxq0tpkkf.lambda-url.eu-west-1.on.aws', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: jsonData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                navigate("/landing-seo-audit-thanks.html");
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoading(false);
            });
    };
    return(
        <>
            <Helmet>
                <title>Netbrain - Weboldal audit</title>
                <meta name="description" content="netbrain weboldal audit" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <div className="page">
                <div className="content">
                    <Container className="header" maxWidth={false} sx={{width: '100vw', overflow: 'hidden',  marginX: 0}}>
                        <Container sx={{ marginY: '10px', width: '100%'}}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Box component="div" style={{ display: 'flex' }}>
                                    <Box component="img" src="/icon.png" width="100" alt="Netbrain Logo" className="icon" />
                                    <Typography
                                        component="h2"
                                        variant="h3"
                                        className="hero1"
                                        sx={{ paddingLeft: '10px', fontSize: { xs: '1.5rem', sm: '2rem' } }}
                                    >
                                        NetBrain
                                    </Typography>
                                </Box>
                             </Stack>
                        </Container>
                    </Container>
                    <Container sx={{marginY: "100px"}} className="blog-page">
                        <Container id="blog" className="blog">
                            <Stack direction="column" spacing={5}>
                                <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                                    Honlap audit
                                </Typography>
                                <Stack direction={{xs: 'column-reverse', md: 'row' }} spacing={3}>
                                    <div>
                                        <p>
                                            A legtöbb vállalkozás életében az év utolsó pár hónapja a legmozgalmasabb, ekkor indulnak a legnagyobb reklámkampányok, érkezik a legtöbb vásárló.
                                        </p>
                                        <p>
                                            <span className="bold">Vajon a Te oldalad készen áll arra, hogy hatékonyan támogassa üzleti célkitűzéseidet?</span>
                                        </p>
                                        <p>
                                            Színvonalas a szolgáltatásod, kimagasló a marketing kampányod és még divatos a webdesign is? Mindez mit sem ér, ha az oldalad technikailag, funkcionálisan nem megfelelő. Rossz honlappal nekiszaladni a reklámkampányoknak csak kidobott pénz.
                                        </p>
                                        <p>
                                            A <span className="bold">technikai-tartalmi felmérésben segít a honlap audit.</span> A honlap audit célja, hogy felmérje a weboldal teljesítményét, használhatóságát, biztonságát és segítse a SEO-t (keresőmotor optimalizálás).
                                        </p>
                                        <p>
                                            Egy honlap audit során több szempontból vizsgáljuk meg és értékeljük ki a honlapodat.
                                        </p>
                                        <p>
                                            <span className="bold">135 pontos checklistánk segítséget nyújt, hogy feltérképezhessed honlapod hiányosságait és eldönthesd, hogy saját magad javítod a problémákat vagy szakemberhez fordulsz velük.</span>
                                        </p>
                                    </div>
                                    <img src="/nyomoz.webp" alt="nyomozo" width="400" height="400" className="logo mobile-half" />
                                </Stack>
                                <form action="https://cw2csgr45jf47qstufttel4rxq0tpkkf.lambda-url.eu-west-1.on.aws" onSubmit={handleSubmit}
                                      method="POST">
                                    <Stack direction="column" spacing={5} className="row">
                                        <TextField id="name"
                                                   name="name"
                                                   type="text"
                                                   value={formData.name}
                                                   onChange={handleChange}
                                                   label="Keresztnév"
                                                   variant="filled"
                                                   InputLabelProps={{ style: { color: "black" } }}
                                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: 'black'}}
                                                   required />
                                        <TextField id="email"
                                                   name="email"
                                                   value={formData.email}
                                                   onChange={handleChange}
                                                   type="email"
                                                   label="E-mail"
                                                   variant="filled"
                                                   InputLabelProps={{ style: { color: "black" } }}
                                                   sx={{backgroundColor: 'white',  borderRadius: '4px', color: '#54abc9'}}
                                                   required />
                                        <FormControlLabel required classes={{ asterisk: 'Mui-required' }} control={<Checkbox />} label={label} />
                                        <div className="col-lg-12 mt-2">
                                            <button type="submit" className="message-button cta pointer">
                                                Kérem az ingyen letölthető 135 pontos honlap audit checklistát
                                            </button>
                                        </div>
                                    </Stack>
                                </form>
                                <div>
                                </div>
                            </Stack>
                        </Container>
                    </Container>
                </div>
            <LandingFooter/>
            </div>
            {loading && (
                <div style={overlayStyle}>
                    <div style={styles.loaderContainer}>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </>
    )
}

const styles: { loaderContainer: React.CSSProperties } = {
    loaderContainer: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1000, // Az előtérbe helyezi a jelzőt
    },
};

const overlayStyle: CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Féláttetsző fekete háttér
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
};

export default LandingSeoAudit