import {motion, useAnimation, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React, {ReactNode} from "react";
import Footer from "./Footer";
import {Box, Container, Stack, Typography} from "@mui/material";
import Header from "./Header";
import LandingFooter from "./LandingFooter";

function LandingSeoAuditThanks() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain - Rólunk</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
                <meta name="robots" content="noindex,nofollow" />
            </Helmet>
            <div className="page">
                <div className="content">
                    <Header/>
                    <Container sx={{marginY: "100px"}} className="blog-page">
                        <Typography component="h1"  variant="h2" className="hero1" sx={{fontWeight: 'bold'}} gutterBottom>
                            Jól döntöttél!
                        </Typography>
                        <Container id="blog" className="blog">
                            <Stack direction="column" spacing={5}>
                                <p>
                                    A honlap audit checklista linkjét tartalmazó e-mail hamarosan megérkezik a megadott e-mail címre.
                                </p>
                                <p>
                                    Ha nem találnád a beérkezett levelek közt, nézd meg a promóciók és a spam mappát, ha itt sem találod, akkor írj az <a href="mailto:info@netbrain.hu">info@netbrain.hu</a> e-mail címre és elküldjük Neked.
                                </p>
                                <p>
                                    Köszönjük:
                                <br/>
                                    Netbrain csapata
                                </p>
                                <p>
                                    Amíg megérkezik a levél ...
                                </p>
                                <p>
                                    Legutóbbi blogbejegyzéseink:
                                    <ul>
                                        <li>
                                            <a href="/seo-roviden-erthetoen.html">SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés</a>
                                        </li>
                                        <li>
                                            <a href="/landing_vagy_weboldal.html">Landing oldalra vagy weboldalra van szükséged?</a>
                                        </li>
                                        <li>
                                            <a href="/mennyibe_kerul_egy_weblap.html">Mennyibe kerül 2024-ben egy weblap Magyarországon?</a>
                                        </li>
                                        <li>
                                            <a href="/weblap_frissites.html">Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését válaszd?</a>
                                        </li>
                                    </ul>
                                </p>
                                {/*<Stack direction="column" spacing={5}>
                                    <a href="/seo-roviden-erthetoen.html" style={{textDecoration: 'none'}}>
                                        <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                            <img src="/seo_boszi.webp" className="logo"/>
                                            <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                                <h2>
                                                    SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés
                                                </h2>
                                                <p>
                                                    Lépten-nyomon sűrűn belebotlani a SEO kifejezésbe, az ingyenes SEO gyorstalpalókon keresztül a
                                                    méreg drága, havi díjas SEO szolgáltatásokig. Az ember önkéntelenül is elcsodálkozik rajta, hogy
                                                    akkor most ez ilyen egyszerű, ingyen is megtanulható, vagy inkább mágikus tudomány, amire
                                                    specialistát kell alkalmazni. Lehet ez egyszerre mindkettő, vagy egyik sem? Az igazság - mint
                                                    szinte mindig – valahol félúton van.
                                                </p>
                                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                            </Stack>
                                        </Stack>
                                    </a>
                                    <a href="/landing_vagy_weboldal.html" style={{textDecoration: 'none'}}>
                                        <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                            <img src="/blog_landing_web.webp" className="logo"/>
                                            <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                                <h2>
                                                    Landing oldalra vagy weboldalra van szükséged?
                                                </h2>
                                                <p>
                                                    Ebben a blogban először is tisztába tesszük, hogy mi a különbség a landing és a
                                                    weboldal között, megnézzük melyiknek mi a célja. Végig vesszük a landing oldalak
                                                    fő típusait. Kiszámoljuk hány landing oldalra lehet szükséged. A cikk végére
                                                    látni fogod, hogy a landing oldal és a weboldal nem helyettesíti egymást, így
                                                    mindkettőre szüksége lesz egy vállalkozásnak hosszú távon.
                                                </p>
                                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                            </Stack>
                                        </Stack>
                                    </a>
                                    <a href="/mennyibe_kerul_egy_weblap.html" style={{textDecoration: 'none'}}>
                                        <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                            <Box component="img" src="/blog_cost.webp" className="logo" sx={{marginY: '20px'}}/>
                                            <Stack direction="column" alignContent="space-between" alignItems="center" >
                                                <h2>
                                                    Mennyibe kerül 2024-ben egy weblap Magyarországon?
                                                </h2>
                                                <p>
                                                    Gyakran találkozni azzal a kérdéssel, hogy mennyiből lehet elkészíteni egy honlapot.
                                                    Nem könnyű egy konkrét számot meghatározni, de sorra tudjuk venni, hogy
                                                    milyen tényezőket vegyél figyelembe, mikor szakembert választasz.
                                                </p>
                                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                            </Stack>
                                        </Stack>
                                    </a>
                                    <a href="/ingyenes_weblap.html" style={{textDecoration: 'none'}}>
                                        <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                            <Box component="img" src="/blog_free.webp" className="logo" sx={{marginY: '20px'}}/>
                                            <Stack direction="column" alignContent="space-between" alignItems="center"  spacing={3}>
                                                <h2>
                                                    Teljesen ingyenes weboldal készítés lehetséges? Ingyen honlap készítés programozói tudás
                                                    nélkül? Mire használható az ingyenes weblap?
                                                </h2>
                                                <p>
                                                    Cikkünkben megmutatjuk, hogy az ingyenesen elkészített honlapoknak valójában milyen
                                                    költségei vannak. Szó lesz a legnépszerűbb ingyenes tartalom kezelő rendszerről, a
                                                    WordPressről, amely akár programozói tudás nélkül is használható. Megnézzük milyen előnyei
                                                    és hátrányai vannak ezeknek az ingyenes sablon webodalaknak, illetve az egyedi készítésű
                                                    honlapoknak.
                                                </p>
                                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                            </Stack>
                                        </Stack>
                                    </a>
                                    <a href="/weblap_frissites.html" style={{textDecoration: 'none'}}>
                                        <Stack direction={{xs: 'column', md: 'row' }} spacing={3}>
                                            <Box component="img" src="/blog_refresh.webp" className="logo" sx={{marginY: '20px'}}/>
                                            <Stack direction="column" alignContent="space-between" alignItems="center" spacing={3}>
                                                <h2>
                                                    Miért kell rendszeres honlap karbantartás? Veszélyes is lehet egy nem frissített weblap?
                                                    Rendszeres vagy alkalmi frissítésre van szükség?
                                                </h2>
                                                <p>
                                                    Ebben a cikkben végignézzük, hogy mikor szorul a holnapod karbantartásra, illetve az alábbi
                                                    kérdésekre keressük a választ: Van-e valami szabálya a rendszeres, illetve az alkalmi
                                                    frissítésnek? Milyen veszélyeket rejthet egy elavult honlap? Mikor érdemes új weboldalban
                                                    gondolkodni? Évente új honlap kell?
                                                </p>
                                                <p style={{color: '#54abc9'}}>Tovább a cikkre...</p>
                                            </Stack>
                                        </Stack>
                                    </a>
                                </Stack>*/}

                            </Stack>
                        </Container>
                    </Container>
                </div>
            <LandingFooter/>
            </div>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
        </>
    )
}

export default LandingSeoAuditThanks