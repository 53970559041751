import {Container, Grid, Box, Typography} from "@mui/material";
import {motion, useAnimation, Variants} from "framer-motion";
import { useInView } from 'react-intersection-observer';
import React, {ReactNode} from "react";


function Service() {

    const divVariants: Variants = {
        hidden: { x: '100%', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    };

    interface DivSectionProps {
        children: ReactNode;
    }

    const DivSection: React.FC<DivSectionProps> = ({ children }) => {
        const controls = useAnimation();
        const [ref, inView] = useInView({
            triggerOnce: true,
            threshold: 0.1
        });

        React.useEffect(() => {
            if (inView) {
                controls.start('visible');
            }
        }, [controls, inView]);

        return (
            <Grid item xs={12} sm={6}>
                <Box
                    component="div" //component={motion.div}
                    ref={ref}
                    //initial="hidden"
                    //animate={controls}
                    //variants={divVariants}
                    className="service-item"
                    //sx={{margin: '20px'}}
                 >
                    {children}
                </Box>
            </Grid>
        );
    };
    return(
        <>
            <div>
                <Container className="service" sx={{width: '100vw'}}>
                    <Grid container spacing={3}>
                        <DivSection>
                            <motion.img whileHover={{ scale: 1.2 }} src="/kanna.webp" width="100" height="100" alt="logo" className="service-icon"/>
                            <p style={{lineHeight: '140%'}}>
                                Egy kezdő vállalkozásnak elég egy egyszerű weboldal, azonban ahogyan fejlődik a
                                vállalkozásod, úgy szükséges lekövetned azt technikailag és tartalmilag is. Ne hagyd, hogy
                                versenytársaid csillogó honlapjának nyomába se érjen a weblapod, miközben te szakmailag jobb
                                vagy.
                            </p>
                            <a className="cta" href="#kapcsolat" style={{textDecoration: 'none'}}><div className="service-cta">Valósítsuk meg együtt elképzeléseidet</div></a>
                        </DivSection>
                        <DivSection>
                            <motion.img whileHover={{ scale: 1.2 }} src="/pajzs.webp" width="100" height="100" alt="logo" className="service-icon"/>
                            <p style={{lineHeight: '140%'}}>
                                A sablonokból készített oldalaknál elég egy nem futtatott frissítés és megtörténhet a baj. Nincs
                                helye a félmegoldásoknak, válts egyedileg készített oldalra, ahol a biztonsági kockázat is kisebb. Éppen elég kihívás egy céget vezetni anélkül is, hogy egy nagy kampány közepén frissítésekkel
                                és hibaüzenetekkel bajlódj.
                            </p>
                            <a className="cta" href="#kapcsolat" style={{textDecoration: 'none'}}><div className="service-cta">Itt az ideje áttekinteni, frissíteni a honlapodat</div></a>
                        </DivSection>
                        <DivSection>
                            <motion.img whileHover={{ scale: 1.2 }} src="/seo.webp" width="100" height="100" alt="logo" className="service-icon"/>
                            <p style={{lineHeight: '140%'}}>
                                A legjobb marketing kampányod is kivéreztetheti, ha a honlapodról lepattannak az érdeklődők.
                                Egy technikailag és tartalmilag jól felépített honlapot szeretnek a keresőmotorok, így erőlködés
                                nélkül is juthatsz ügyfelekhez.
                            </p>
                            <a className="cta" href="#kapcsolat" style={{textDecoration: 'none'}}><div className="service-cta">Keressük meg weboldalad hibáit</div></a>
                        </DivSection>
                        <DivSection>
                            <motion.img whileHover={{ scale: 1.2 }} src="/mobil.webp" width="100" height="100" alt="logo" className="service-icon"/>
                            <p style={{lineHeight: '140%'}}>
                                Mára a mobiltelefon az egyik meghatározó munkaeszköz, így nem megengedhető, hogy
                                szétcsússzon a honlapod a kijelzőn, és a felgyorsult világunkban senki se vár nyomasztóan
                                hosszú másodpercekig, hogy betöltődjön az oldalad.
                            </p>
                            <a className="cta" href="#kapcsolat" style={{textDecoration: 'none'}}><div className="service-cta">Írj nekünk ha modern weboldalt szeretnél</div></a>
                        </DivSection>

                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Service