import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import './App2.css';
import Root from "./components/Root";
import BlogFree from "./components/BlogFree";
import BlogRefresh from "./components/BlogRefresh";
import AboutUs from "./components/AboutUs";
import Blog from "./components/Blog";
import BlogCost from "./components/BlogCost";
import BlogLandingVsWebpage from "./components/BlogLandingVsWebpage";
import BlogSeoWitch from "./components/BlogSeoWitch";
import LandingSeoAudit from "./components/LandingSeoAudit";
import LandingSeoAuditThanks from "./components/LandingSeoAuditThanks";
import CookieBanner from "./components/CookieBanner";
import BlogGoogleMisconception from "./components/BlogGoogleMisconception";

function App() {

    return (
        <>
            {/*<CookieBanner/>*/}
            <Router>
                <Routes>
                    <Route path="/" element=<Root/> />
                    <Route path="/blog.html" element=<Blog/> />
                    <Route path="/rolunk.html" element=<AboutUs/> />
                    <Route path="/ingyenes_weblap.html" element=<BlogFree/> />
                    <Route path="/weblap_frissites.html" element=<BlogRefresh/> />
                    <Route path="/mennyibe_kerul_egy_weblap.html" element=<BlogCost/> />
                    <Route path="/landing_vagy_weboldal.html" element=<BlogLandingVsWebpage/> />
                    <Route path="/seo-roviden-erthetoen.html" element=<BlogSeoWitch/> />
                    <Route path="/google-tevhitek.html" element=<BlogGoogleMisconception/> />
                    <Route path="/landing-seo-audit.html" element=<LandingSeoAudit/> />
                    <Route path="/landing-seo-audit-thanks.html" element=<LandingSeoAuditThanks/> />
                </Routes>
            </Router>
        </>
  );
}

export default App;
