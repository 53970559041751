import {Box, Button, Checkbox, Dialog, DialogTitle, DialogContent, Container, TextField, Stack, Typography, FormControlLabel} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {color} from "framer-motion";
import {ChangeEvent, FormEvent, useState} from "react";

function LandingFooter() {

    return(
        <>
            <Container maxWidth={false} sx={{backgroundColor: '#54abc9', paddingY: "10px"}} className="landing-footer">
                <Container className="container">
                    <div className="footer-area footer-area--l1">
                        <Stack direction={{xs: 'column', md: 'row'}} justifyContent="space-between">
                            <Box component="div">
                                <Typography variant="h6" color="white" gutterBottom>
                                    Célunk az, hogy büszke légy a honlapodra!
                                </Typography>

                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white" >További linkek</Typography>
                                <ul className="footer-widgets__list">
                                    <li><a href="/adatvedelmi.pdf" target="_blank">Adatkezelési
                                        Tájékoztató</a></li>
                                </ul>
                            </Box>
                            <Box component="div">
                                <Typography variant="h6" color="white">Kapcsolat</Typography>

                                <ul className="footer-widgets__list footer-widgets--address">
                                    <li>
                                        <Stack direction="row" spacing={2} justifyContent="flex-start">
                                            <a href="https://facebook.com/netbrain.hu" target="_blank" aria-label="Kövess Facebookon"><FacebookIcon aria-label="Facebook icon" fontSize="large" sx={{color: 'white'}}/></a>
                                            <a href="https://instagram.com/netbrain_hu" target="_blank" aria-label="Kövess Instagramon"><InstagramIcon aria-label="Instagram icon" fontSize="large" sx={{color: 'white'}}/></a>
                                        </Stack>
                                    </li>
                                    <li>
                                        <LocationOnIcon sx={{paddingRight: '0.5rem'}}/>
                                        <Typography component="p" variant="body1">1101 Budapest, Kőbányai út 43/B</Typography>
                                    </li>
                                    <li>
                                        <PhoneIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="tel:36202576845"><Typography component="p" variant="body1">+36 20 257 6845</Typography></a>
                                    </li>
                                    <li>
                                        <MailOutlineIcon sx={{paddingRight: '0.5rem'}} />
                                        <a href="mailto:info@netbrain.hu"><Typography component="p" variant="body1">info@netbrain.hu</Typography></a>
                                    </li>
                                </ul>

                            </Box>
                        </Stack>
                    </div>
                    <div className="footer-copyright footer-copyright--l1">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7">
                                <div className="copyright text-center text-md-start">
                                    <Typography variant="body1" color="white" className="copyright__text">Copyrights © 2024 NetBrain</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </>
    )
}

export default LandingFooter