import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Container} from "@mui/material";
import Header from "./Header";
import NewsLetterPopup from "./NewsLetterPopup";

function BlogRefresh() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - Weblap frissítés</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">Miért kell rendszeres honlap karbantartás? Veszélyes is lehet egy nem frissített weblap?
                            Rendszeres vagy alkalmi frissítésre van szükség? Weboldal felújítást vagy egy új készítését
                            válaszd?
                        </h1>
                    </header>
                    <div className="post-headline">
                        <h2 className="post-headline-title">
                            Ebben a cikkben végignézzük, hogy mikor szorul a holnapod karbantartásra, illetve az alábbi
                            kérdésekre keressük a választ: Van-e valami szabálya a rendszeres, illetve az alkalmi
                            frissítésnek? Milyen veszélyeket rejthet egy elavult honlap? Mikor érdemes új weboldalban
                            gondolkodni? Évente új honlap kell? Meddig jó egyáltalán egy honlap?
                        </h2>
                    </div>
                    <div className="post-image center">
                        <img src="/blog_refresh.jpg" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <h4>
                            Rendszeres karbantartás és frissítés
                        </h4>
                        <p>
                            Gyakori tévhit, hogy ha elkészült a honlapod, akkor már hátra lehet dőlni, és többet nem kell
                            törődni vele. Ha bele gondolsz, a műszaki eszközöknek, programoknak bizony szüksége van
                            karbantartásra, frissítésre. Ahogyan a kocsidat elviszed a szervízbe vagy a telefonodra letöltöd a
                            frissítéseket, ugyanígy a honlapoddal is foglalkozni kell. Alábbiakban a legtipikusabb
                            problémákat soroljuk fel.
                        </p>
                        <h4>
                            Frissítésre szoruló honlapok jelei
                        </h4>
                        <p>
                            <span className="bold">Kinézet –</span> Ez az, ami első pillantásra pozitív vagy negatív benyomást fog kelteni a látogatóban.
                            Egyrészt a technológia is fejlődik, másrészt a webdesignban is folyamatosan változnak a trendek,
                            így ránézésre is meg lehet állapítani egy oldalról, hogy az bizony régi, ódivatú. A szépség az
                            eléggé szubjektív fogalom, viszont a honlapodnak tükröznie kell a Te vállalkozásodat. Egy
                            babaholmikkal foglalkozó oldalnál a púderrózsaszín és a lepkék harmonikus hatást kelthetnek,
                            azonban, ha fémmegmunkáló gépeket forgalmazol, lehet nem ez a jó választás… Bármilyen
                            külsőt is választasz az legyen összhangban azzal, amit csinálsz, illetve azzal, amit szeretnél, ha
                            gondolnának rólad és a vállalkozásodról.
                        </p>
                        <p>
                            A gyenge felhasználói élmény hatására sokan meggondolhatják vásárlási szándékukat. Másrészt
                            amennyiben vállalkozásod lógót, vagy arculatot váltott, azt a honlapod megjelenésében is le kell
                            követned, hiszen azt hihetik, hogy rossz oldalon járnak.
                        </p>
                        <p>
                            <span className="bold">Lassú betöltés –</span> A mai rohanó világunkban senki se vár hosszú kínos másodpercekig arra, hogy
                            megjelenjen a honlapod. A gyenge teljesítmény pedig a keresőmotoroknak is szemet fog szúrni,
                            így pl. a Google is lejjebb rangsorolja a találatok között a lassabb oldalakat. Weblapod
                            lassúságának javítása érdekében csökkenteni, illetve optimalizálni lehet a képek, a videók
                            méretét, illetve megjelenítését.
                        </p>
                        <p>
                            <span className="bold">Nem mobilbarát megjelenítés –</span> Manapság az okostelefonok világában az emberek egyre
                            inkább a mobiltelefonjukat használják a honlapok megtekintésére is, így ahhoz, hogy ne veszíts
                            ügyfeleket, elengedhetetlen az, hogy ne essen szét az oldalad a különböző képernyőméreteken
                            (reszponzivítás). A gyenge felhasználói élményt a keresőmotorok sem díjazzák és hátrébb
                            sorolnak a találatok közt.
                        </p>
                        <p>
                            <span className="bold">Elavult információk –</span> Nem jó az e-mailcím, telefonszám vagy a boltod címe az oldaladon, így
                            nem talál meg az ügyfél. Már nem is azzal foglalkozol, ami a honlapodon szerepel. Az árak
                            manapság gyorsan változnak, így weblapodon is szükséges frissíteni.
                        </p>
                        <p>
                            <span className="bold">Friss tartalmak hiánya –</span> Megfelelően optimalizált, friss tartalmak nem csak a keresőmotorokat
                            vonzzák, hanem az ügyfeleket is. Az emberek ritkán olvasnak öt évvel ezelőtti újdonságokat –
                            amik azóta már lehet nagyon is elavultak.
                        </p>
                        <p>
                            <span className="bold">Nem felhasználóbarát –</span> Fontos, hogy milyen hatást gyakorolsz a honlapod látogatóira. Egy jó
                            honlapon könnyen és egyszerűen lehet navigálni, logikus és átlátható a felépítés. Hiába jó a
                            szolgáltatásod, ha a honlapodra tévedő emberke bosszankodva kattingat percekig, negatív
                            szájízzel fog távozni az oldaladról.
                        </p>
                        <p>
                            <span className="bold">Zsúfoltság –</span> Egyrészt a letisztult, korszerű megjelenés része, hogy inkább képeket használ egy
                            oldal, másrészt a túl sok szövegben elveszik a lényeg. Az emberek rémesen türelmetlenek,
                            azonnal a lényeget keresik az oldaladon. A magyarázatokat, plusz infókat, rejtsd el, akár tedd a
                            „gyakran ismételt kérdések közé” lenyíló formában. A hosszú meséket pedig hagyd meg a blog
                            részeknek. Azok az emberek, akik igazán érdeklődnek a vállalkozásod, terméked iránt, szeretnék
                            látni a részleteket, azok szívesen olvasnak róla többet is, így a blogodat is aktívan lapozgatják.
                        </p>
                        <p>
                            <span className="bold">Nem mutatja be vállalkozásod fejlődését –</span> Honlapod azt tartalmazza, ahol évekkel ezelőtt
                            álltál, és nem azt, ahol jelenleg tartasz. Kezdőként lehet egyedül pár termékkel, pár
                            szolgáltatással indítottál, de mára az évek során egy nagy csapattal a hátad mögött egyre több
                            mindent árulsz, egyre bonyolultabb feladatokat tudsz elvégezni. Vállalkozásod életében az évek
                            során sok minden változik, fejlődik. Akár módosíthattad a fókuszod, a célcsoportod körét, vagy
                            javíthattad marketing módszereidet is. A vállalkozásod fejlődését a weblapoddal is le kell
                            követni, hiszen az oldaladon keresztül érkezők az évekkel ezelőtti árukat, szolgáltatásokat fogják
                            keresni, és nem azt, amivel jelenleg foglalkozol.
                        </p>
                        <p>
                            <span className="bold">Nem létező, elavult linkek –</span> keresőmotorok ez alapján is rangsorolnak, ezért a linkeket időről
                            időre át kell nézni.
                        </p>
                        <p>
                            <span className="bold">Fórumok moderációja, karbantartása –</span> amennyiben weblapodon fórum található, ott
                            szükséges automatikus szűrőket használnod, és azokat időről időre frissíteni, különben kéretlen
                            üzenetek hada áraszthat el.
                        </p>
                        <p>
                            <span className="bold">Nincs összekötve a közösségi médiával –</span> amennyiben Facebookon, Instagrammon stb. már
                            követőbázist építettél ki, akkor azt kösd össze a honlapoddal is.

                            Ha fentieket nem frissíted időről időre, akkor a honlapodon keresztül nem érkezik hozzád
                            megfelelő minőségű és mennyiségű vásárló. Keresőmotoroknak (SEO) nem fogsz megfelelni,
                            így ők sem fognak feléd forgalmat terelni. Így bevételtől fogsz elesni, de „nagyobb baj”
                            ezekkel nem ér, már ha neked az nem fontos, hogy a honlapod bevételt hozzon. Azonban ha
                            az alábbiak karbantartásával sem foglalkozol, akkor kiberbűnözőkkel és büntetésekkel kell
                            szembe nézned.
                        </p>
                        <p>
                            <span className="bold">Sablonokat nem frissíted –</span> Amennyiben honlapod pl. a WordPresst használja, akkor szükséges
                            rendszeresen frissíteni a WordPress alapot, sablont és bővítményeket, különben honlapod
                            lelassulhat, működése akadozhat, illetve támadásoknak teszed ki az oldalad.
                        </p>
                        <p>
                            <span className="bold">Nem felel meg az adatvédelmi előírásoknak –</span> A GDPR rendelet alkalmazása viszonylag új,
                            így az évekkel ezelőtt készített honlapok valószínűleg nem felelnek meg a követelményeknek.
                            Már nem elég az, hogy feltünteted, hogy az oldalad sütiket használ, hanem a felhasználónak
                            egyértelműen beleegyezését is kell adnia.
                        </p>
                        <p>
                            <span className="bold">Nem biztonságos az oldalad –</span> a technológiák fejlődésével újabb és újabb biztonsági
                            protokollokat vezetnek be, amiknek az évekkel ezelőtt honlapok még nem feltétlenül felelnek
                            meg, így az adatszivárgás és visszaélések veszélye nagyobb.
                        </p>
                        <p>
                            A fentieken felül, van még egy nyomos ok, arra, hogy ideje frissíteni a honlapod:
                        </p>
                        <p>
                            <span className="bold">Ha már nem tetszik, amit látsz, ha nem örömmel és büszkeséggel tölt el a weblapod, ha úgy
                                érzed, hogy már nem képvisel téged, vagy a vállalkozásodat, ha szégyenled másoknak
                                megmutatni, vagy csak a versenytársaidé sokkal szebb, akkor ne hagyd, hogy ezek az
                                érzések kínozzanak, hanem frissítsd a honlapod.</span>
                        </p>
                        <h4>
                            Milyen gyakran szükséges frissíteni, illetve rendszeresen vagy alkalmanként
                        </h4>
                        <p>
                            Alapvető ökölszabályokat nehéz megfogalmazni.
                        </p>
                        <p>
                            Amint láthattad a fenti felsorolásban sok minden felmerülhet a honlapod használata közben.
                            Nehéz szabályokat felállítani.
                        </p>
                        <p>
                            Vannak egyedi, egyszer-egyszer felmerülő kérdések, ezeket hívhatjuk egyedi karbantartási
                            szükségleteknek.
                        </p>
                        <p>
                            Ha technikai hibáid vannak (lassú betöltés, nem responzív), ha nem biztonságos az oldalad,
                            támadás érte stb., akkor ne habozz, ne várj be más frissítési igényt.
                        </p>
                        <p>
                            Amennyiben profilt váltottál, bővítettél, megváltozott a vállalkozásod külső megjelenése, már az
                            első marketing kampány előtt 2-3 hónappal kezd el honlapod ráncfelvarrását is.
                        </p>
                        <p>
                            Rendszeres frissítések közül a sablonok és a biztonsági frissítések mellett a keresőoptimalizálást
                            kell kiemelni.
                        </p>
                        <p>
                            Ha sablonokat használsz, akkor minimum fél évente érdemes megnézni, hogy milyen frissítések
                            jelentek meg. Egyedi honlapoknál lehet ritkábban foglalkozni ezzel.
                        </p>
                        <p>
                            Az, hogy milyen sűrűn szükséges módosítani a honlapodon, egyrészt attól függ, hogy a
                            vállalkozásod milyen gyorsan fejlődik, illetve, hogy mennyire dinamikusan változik az iparágad
                            (hiszen, hogy versenyben maradj le kell követned a versenytársak fejlesztéseit).
                        </p>
                        <p>
                            Érdemes hosszú távú kapcsolatot kiépíteni a honlapodat készítő vállalkozással – akár havi
                            rendelkezésre állás formájában – hogy a kisebb-nagyobb problémákat, ráncfelvarrásokat azonnal
                            orvosolni lehessen. Illetve, hogy a további fejlesztési irányokat időben be lehessen tervezni, és ne
                            azért ne tudj elindítani egy marketing kampányt, mert az újonnan felkért fejlesztőd még a
                            honlapod felépítésével ismerkedik, így sokkal több időbe (és pénzbe) fog kerülni ez neked.
                        </p>
                        <h4>
                            Régi honlap ráncfelvarrása vagy új honlap készítése
                        </h4>
                        <p>
                            Apró módosításokat, folyamatos frissítéseket a honlapodon addig lehet végrehajtani, amíg azok
                            még technikailag megoldhatóak.
                        </p>
                        <p>
                            Amennyiben a holnapodon teljes arculatot váltasz, akkor mérlegelni kell, hogy mi éri meg
                            jobban. Informatikusoddal ki tudjátok számolni, hogy mennyi idő (és pénz), ha a régi oldalt
                            fejlesztitek tovább, vagy ha egy új oldal készül.
                        </p>
                        <p>
                            A ráncfelvarrásokkal, frissítésekkel a probléma akkor adódik, ha a weblap technológiailag nem
                            támogatott, nem kompatibilis elemeket használ.
                        </p>
                        <p>
                            Alapvetően azt kell látnod, hogyha honlapodat folyamatosan karban tartod, akkor sok évig
                            nem kell lecserélned. Viszont, ha 2-3 éve nem nyúlt senki hozzá, akkor már valószínűleg
                            technológiailag egyszerűbb (és olcsóbb is) lecserélni az egész oldalt, mint toldozni-foltozni.
                        </p>
                        <p>
                            Összefoglalásul kiemelkedően fontos, hogy rendszeresen frissítsd weboldalaidat, hogy fenntartsd
                            a biztonságot, fokozd a felhasználói élményt és optimalizáld a SEO-t (keresőoptimalizálás), így
                            honlapod több ügyfelet hozhat számodra. A rendszeres karbantartással weblapod tovább
                            használható marad, és a jövőbeni frissítések is összességében olcsóbbak lesznek.
                        </p>
                        <div style={{color: "#54abc9"}}>
                            <p>
                                Itt az ideje a honlapauditnak! Nézzük át együtt honlapodat, és találjuk meg a számodra
                                legkedvezőbb megoldásokat.
                            </p>
                        </div>
                    </div>
                </article>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogRefresh