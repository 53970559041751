import {motion, useScroll} from "framer-motion";
import {Helmet} from "react-helmet";
import React from "react";
import Footer from "./Footer";
import {Container} from "@mui/material";
import Header from "./Header";
import NewsLetterPopup from "./NewsLetterPopup";

function BlogSeoWitch() {

    const { scrollYProgress } = useScroll();

    return(
        <>
            <Helmet>
                <title>Netbrain Blog - SEO röviden, érthetően</title>
                <meta name="description" content="netbrain egyedi weboldal fejlesztés" />
            </Helmet>
            <motion.div
                className="progress-bar"
                style={{ scaleX: scrollYProgress }}
            />
            <Header/>
            <Container className="blog-page">
                <article className="post">
                    <header className="entry-header">
                        <h1 className="entry-title">SEO boszorkánykonyhája - Varázsige vagy szemfényvesztés</h1>
                    </header>
                    <div className="post-headline">
                        <h2 className="post-headline-title">
                            Lépten-nyomon sűrűn belebotlani a SEO kifejezésbe, az ingyenes SEO gyorstalpalókon keresztül a
                            méreg drága, havi díjas SEO szolgáltatásokig. Az ember önkéntelenül is elcsodálkozik rajta, hogy
                            akkor most ez ilyen egyszerű, ingyen is megtanulható, vagy inkább mágikus tudomány, amire
                            specialistát kell alkalmazni. Lehet ez egyszerre mindkettő, vagy egyik sem? Az igazság - mint
                            szinte mindig – valahol félúton van.
                        </h2>
                    </div>
                    <div className="post-image center">
                        <img src="/seo_boszi.jpg" className="blog-image"/>
                    </div>
                    <div className="post-content">
                        <h3>
                            Óvakodj a szélsőségektől, kerüld el a túlmisztifikált, túlárazott termékeket, szolgáltatásokat.
                        </h3>
                        <h4>
                            Mi is a SEO?
                        </h4>
                        <p>
                            <span className="bold">Search Engine Optimization </span> vagyis Keresőmotor Optimalizálás, rövidebben kereső optimalizálás.
                        </p>
                        <h4>
                            Milyen keresőmotor?
                        </h4>
                        <p>
                            Bár használatban van még a Yahoo! , a Bing stb., de a <span className="bold"> Google messze a legelterjedtebb keresőmotor</span>,
                            a globális online forgalom 92 százaléka tőlük származik (2023-ban 85 milliárd látogatójuk volt).
                            Így elengedhetetlen, hogy az ő algoritmusaiknak megfelelően épüljön fel a honlapod.
                            (Ezért a továbbiakban az egyszerűség kedvéért a Google-re összpontosítunk.)
                        </p>
                        <h4>
                            Hogyan működik a keresőmotor?
                        </h4>
                        <p>
                            Először <span className="bold">feltérképezi</span> az oldalt,
                            <span className="bold"> indexálja</span> és <span className="bold">értelmezi</span>,
                            majd mikor valaki rákeres egy információra a saját <span className="bold">rangsor</span>
                            a alapján kiajánlja az oldalakat. (Figyelem, sokan nem is
                            veszik észre, de az első x találat egy Google keresésnél, az nem is az organikus találat,
                            hanem fizetett hirdetés.)
                        </p>
                        <p>
                            Ez egy automatikus rendszer, aminek működéséről sokat tudunk, minthogy a Google saját
                            maga megosztja az információkat... de a mechanizmus nagyon összetett és bonyolult.
                        </p>
                        <h4>
                            Mit tudsz tenni?
                        </h4>
                        <p>
                            <span className="bold">Segítsd a keresőmotort!</span> Figyelj, hogy ne fusson hibára,
                            hogy fel tudja térképezni és megfelelően értelmezni a tartalmaidat. Alapvetően egy jó weboldal esetében
                            nincs semmi teendőd. Egy kis idő (napok-hónapok) mire a rendszer beáll és megfelelő ‘helyre’ teszi az oldalt.
                            Ha a kereső nem ad ki találatként organikusan, akkor meg kell nézni, hogy mely pontokon lehet javítani,
                            finomítani az oldaladon. (Lehet ez technikai probléma is, de lehet nem tart elég megbízhatónak, vagy éppen
                            nem átlátható az oldalad, nem elég érdekes, kevés hasznos információkat tartalmaz, hiányoznak a megfelelő
                            kulcsszavak stb. ... sok-sok pontot kell ilyenkor megvizsgálni.)
                        </p>
                        <h4>
                            Meg lehet-e “hekkelni” a keresőmotort?
                        </h4>
                        <p>
                            <span className="bold">Rövid távon igen, de hosszú távon büntetni fog érte!</span>
                        </p>
                        <p>
                            Fentiek elapján kerüld el azokat, akik gyorsan (hiszen a Google saját leírása alapján is több,
                            hét - hónap az átfutási idő) hatalmas eredményeket ígérnek.
                        </p>
                        <h4>
                            Ha egyszer beállítottunk a honlapon mindent, akkor hátradőlhetünk?
                        </h4>
                        <p>
                            Biztosan nem. Ahogyan már egy
                            <a href="/weblap_frissites.html" target="_blank" style={{color: '#54abc9'}} title="Frissítésre szoruló honlapok jelei"><span className="italic"> korábbi cikkünkben </span></a>
                            kifejtettük, <span className="bold">egy oldalt folyamatosan karban kell tartani, és frissíteni is szükséges</span>.
                            Ugyanígy ezeket a beállításokat is rendszeresen ellenőrizni kell. Sőt minthogy a Google
                            is folyamatosan fejleszti a keresőmotorját, neked időről-időre hozzá kell igazítanod a weboldaladat.
                        </p>
                        <h4>
                            Akkor az a megoldás, ha havi szinten folyamatosan optimalizálod (optimalizáltatod) a honlapodat?
                        </h4>
                        <p>
                            Ahogy fent már írtunk mindennek átfutási ideje van... egyik napról a másikra nem lesz eredmény.
                            <span className="bold">Meg kell várni amíg ‘beérnek’ a változtatások.</span>
                            Természetesen, ha technikai hiba van, ha egy terméked, célcsoportod stb. változott, akkor ne várj hónapokig a javítással, frissítéssel.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            A SEO az egy hosszú távú befektetés, amit <span className="bold">rendszeresen érdemes megismételned</span>. Nem hoz olyan gyors
                            és nagy eredményeket, mint egy marketing kampány, egy fizetett hirdetés a Google oldalon, de segít
                            abban, hogy organikusan (fizetés nélkül) is megtaláljanak az ügyfeleid, leendő ügyfeleid. Viszont,
                            ha az oldaladon hibák vannak, akkor a marketing kampányod is megbukhat.
                        </p>
                        <p>
                            A SEO nem egy tisztán marketing eszköz, hiszen rengeteg technikai ponton tudsz kicsúszni a keresőmotorok
                            ‘kezei közül’.  A marketing stratégia segít előrébb jutni a rangsorban, de ha tisztában vagy vállalkozásod
                            céljaival, célcsoportjaival és hogy miként kívánsz eljutni hozzájuk, akkor akár saját magad is összeállíthatod
                            a SEO-hoz szükséges információkat. Ahhoz, hogy honlapod önazonos legyen a vállalkozásoddal, és támogassa az elérni kívánt üzleti céljaidat,
                            <ul>
                                <li>
                                    át kell gondolnod, hogy milyen problémákra, milyen megoldásokat nyújtasz,
                                </li>
                                <li>
                                    meg kell határoznod, hogy mire, kire fókuszál a honlapod, illetve milyen cselekvést/cselekvéseket vársz el az oldalra látogatóktól,
                                </li>
                                <li>
                                    össze kell írnod a vállalkozásod kulcskifejezéseit és meg kell nézned, hogy versenytársaid milyen kulcskifejezéseket használnak.
                                </li>
                            </ul>
                            Majd ezeket szem előtt tartva pontról pontra be lehet állítani honlapodat a legnagyobb hatékonyság elérése érdekében.
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <span className="bold">A technikai-tartalmi felmérésben segíthet egy webaudit, amely
                                során felmérjük az oldalad teljesítményét, használhatóságát, biztonságát, és keresőmotor optimalizáltságát.
                            </span>
                        </p>
                        <div style={{color: "#54abc9"}}>
                            <p>
                                Webauditot kérek, nézzük át együtt a honlapomat!
                            </p>
                        </div>
                    </div>
                </article>
            </Container>
            <Footer/>
            <a href="#kapcsolat" className="offer cta">Ajánlatkérés</a>
            <NewsLetterPopup/>
        </>
    )
}

export default BlogSeoWitch